<template>
  <li class="nav-item col-12 col-lg-auto mobile-toggle-link" data-bs-dismiss="offcanvas" data-bs-target="#bdNavbar">
    <button class="nav-link py-2 px-0 px-lg-4" @click="handleClick">INICIO</button>
  </li>
</template>

<script>
import { useModalStore } from '@stores/modalStore.js';

export default {
  name: "WelcomeButton",
  setup() {
    const modalStore = useModalStore();

    const handleClick = () => {
      if (window.scrollY === 0) {
        // If already at the top, open the modal directly
        modalStore.openModal();
      } else {
        // Scroll to the top smoothly and handle modal opening when scrolling is complete
        window.scrollTo({ top: 0, behavior: 'smooth' });
        window.addEventListener('scroll', checkScrollPosition);
      }
    };

    const checkScrollPosition = () => {
      // Check if the scroll position is at the top
      if (window.scrollY === 0) {
        modalStore.openModal(); // Open the modal
        window.removeEventListener('scroll', checkScrollPosition); // Remove the scroll listener after reaching the top
      }
    };

    return { handleClick };
  }
};
</script>
